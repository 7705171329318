<pnz-form id="change-password-form">
	<pnz-form-row>
		<pnz-form-group-with-error-block col-sm="12" field="oldPassword" :label="'oldPassword' | localize" type="password" :auto-focus="true" />
	</pnz-form-row>
	<pnz-form-row>
		<pnz-form-group-with-error-block col-sm="12" field="password" :label="'newPassword' | localize" type="password" />
	</pnz-form-row>
	<pnz-form-row>
		<pnz-form-group-with-error-block col-sm="12" field="passwordConfirmation" :label="'confirmPassword' | localize" type="password" />
	</pnz-form-row>
	<pnz-form-row>
		<pnz-empty-row />
	</pnz-form-row>
	<pnz-form-row>
		<pnz-button type="submit" :is-loading="isLoading">{{ 'done' | localize }}</pnz-button>
	</pnz-form-row>
	<pnz-form-row>
		<pnz-empty-row />
	</pnz-form-row>
</pnz-form>